<template>
  <div class="ms-shelve-goods-edit">
    <el-form class="zwx-form edit-form" key="goodsEditForm" ref="goodsEditFormRef" :model="goodsEditForm" :rules="goodsEditFormRules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="110px" label="商品名称：" prop="goodsName">
          <el-input style="width:240px !important" class="zwx-input" v-model.trim="goodsEditForm.goodsName" placeholder="请选择" readonly :disabled="ifDisabled || goodsEditForm.merchandisngState === -1" @focus="showChooseCourseDialog()">
            <template slot="append" v-if="isShowSearch && goodsEditForm.merchandisngState != -1">
              <span><i class="el-icon-search" @click="showChooseCourseDialog"></i></span>
            </template>
            <template slot="append" v-if="isShowDel && goodsEditForm.merchandisngState != -1">
              <span><i style="cursor: pointer;" class="el-icon-delete" @click="clearGoodsName"></i></span>
            </template>
          </el-input>
        </el-form-item>
      </div>
      <!--
      <div class="edit-row">
        <el-form-item label-width="110px" label="商品编号：" prop="goodsNo">
          <el-input style="width:200px !important" class="zwx-input" v-model.trim="goodsEditForm.goodsNo" placeholder="请输入" clearable maxlength="20" :disabled="goodsEditForm.merchandisngState === -1" />
        </el-form-item>
        <el-form-item label-width="110px" label="图片：" prop="coursePic">
          <file-upload ref="fileUploadRef" @showFileList="uploadPhoto" @success="uploadSuccessCallback" accept=".png,.jpg,.JPG,.PNG,.jpeg,.JPEG" :size="50 * 1024 * 1024" sizeInfo="50M">
            <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-show="this.goodsEditForm.coursePic === ''">上传图片</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(goodsEditForm.photoAnnexList)">
            <span v-for="(item, index) in goodsEditForm.photoAnnexList" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
              <span>
                <a style="color: #194dff !important; margin-left: 0px; cursor: pointer" @click="openFilePreview(goodsEditForm.photoAnnexList[0].filePath)">
                  <i :class="item.fileIconShow" style="color: #316cf5 !important"></i>
                  {{ item.fileName }}
                </a>
                <span class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                  <i class="el-icon-upload-success el-icon-circle-check"></i>
                </span>
                <span class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                  <i class="el-icon-close" @click="deletionPositive"></i>
                </span>
              </span>
            </span>
          </div>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="描述：" prop="productDetail">
          <el-input class="zwx-textarea" style="width:530px !important;" type="textarea" maxlength="2000" resize="none" :autosize="{ minRows: 4 }" v-model.trim="goodsEditForm.productDetail" />
        </el-form-item>
      </div>
      -->
      <template>
        <div class="edit-row">
          <el-form-item label-width="110px" label="课程分类：">
            <span v-if="true">{{ goodsEditForm.courseExp.courseTraineeType ? simpleCodeList.find(({ codeNo }) => codeNo === goodsEditForm.courseExp.courseTraineeType).codeName : '-' }}</span>
            <el-select v-else class="zwx-select " popper-class="zwx-select-popper" v-model="goodsEditForm.courseExp.courseTraineeType" disabled placeholder="请选择">
              <el-option v-for="item in simpleCodeList" :key="item.codeNo" :label="item.codeName" :value="item.codeNo"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="110px" label="课程类型：" prop="tcCode">
            <span v-if="true">{{ goodsEditForm.courseExp.courseTrainingType ? simpleCodeList.find(({ codeNo }) => codeNo === goodsEditForm.courseExp.courseTrainingType).codeName : '-' }}</span>
            <cascader-single v-else v-model="goodsEditForm.courseExp.courseTrainingType" ref="cascader" disabled :simpleCodeList="simpleCodeList" expandTrigger="hover" codeTypeCode="5002" @change="changeCascader"></cascader-single>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="110px" label="总学时：" prop="totalClassHour">
            <span v-if="true">{{ goodsEditForm.courseExp.totalClassHour || '-' }}</span>
            <el-input v-else style="width:75px !important" class="zwx-input" disabled v-model.trim="goodsEditForm.courseExp.totalClassHour" />
          </el-form-item>
          <el-form-item label-width="110px" label="必修学时：" prop="requiredHour">
            <span v-if="true">{{ goodsEditForm.courseExp.onlineRequiredClassHour || '-' }}</span>
            <el-input v-else style="width:75px !important" class="zwx-input" disabled v-model.trim="goodsEditForm.courseExp.onlineRequiredClassHour" />
          </el-form-item>
          <el-form-item label-width="110px" label="选修学时：" prop="electiveHour">
            <span v-if="true">{{ goodsEditForm.courseExp.electiveClassHour || '-' }}</span>
            <el-input v-else style="width:75px !important" class="zwx-input" disabled v-model.trim="goodsEditForm.courseExp.electiveClassHour" />
          </el-form-item>
          <el-form-item label-width="110px" label="学时要求：" prop="requiredClassHour">
            <span v-if="true">{{ goodsEditForm.courseExp.requiredClassHour || '-' }}</span>
            <el-input v-else style="width:75px !important" class="zwx-input" disabled v-model.trim="goodsEditForm.courseExp.requiredClassHour" />
          </el-form-item>
        </div>
        <!--
        <div class="edit-row">
          <el-form-item label-width="110px" label="是否签发证书：" prop="signCertificate">
            <el-radio-group style="width:228px !important;" class="zwx-radio-group" v-model="goodsEditForm.signCertificate">
              <el-radio class="zwx-radio" :label="true">是</el-radio>
              <el-radio class="zwx-radio" :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="110px" label="发证机构：" prop="certificateOrgan" v-if="goodsEditForm.signCertificate">
            <el-input style="width:200px !important" class="zwx-input" v-model.trim="goodsEditForm.certificateOrgan" placeholder="请输入" clearable maxlength="50" />
          </el-form-item>
        </div>
        -->
        <div class="edit-row">
          <el-form-item label-width="110px" label="是否推荐展示：" prop="recommendDisplay">
            <el-radio-group style="width:200px !important;" class="zwx-radio-group" v-model="goodsEditForm.recommendDisplay">
              <el-radio class="zwx-radio" :label="true">是</el-radio>
              <el-radio class="zwx-radio" :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="110px" label="有效日期(天)：" prop="expiryDate">
            <el-input style="width:200px !important" class="zwx-input" v-model.trim="goodsEditForm.expiryDate" placeholder="请输入" clearable @input="$validate.number($data['goodsEditForm'], 'expiryDate', 9, 0, 0)" maxlength="9" />
          </el-form-item>
        </div>
      </template>
      <div class="edit-row">
        <el-form-item label-width="110px" label="原价：" prop="price">
          <el-input style="width:200px !important" class="zwx-input" v-model.trim="goodsEditForm.price" placeholder="请输入" clearable @input="$validate.number($data['goodsEditForm'], 'price', -1, 2, 0)" maxlength="22" />
        </el-form-item>
        <el-form-item label-width="110px" label="售价：" prop="salePrice">
          <el-input style="width:200px !important" class="zwx-input" v-model.trim="goodsEditForm.salePrice" placeholder="请输入" clearable @input="$validate.number($data['goodsEditForm'], 'salePrice', -1, 2, 0)" maxlength="22" />
        </el-form-item>
      </div>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" @click="submit(0)">暂存</el-button>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submit(1)">上架</el-button>
    </fixed-footer>

    <!-- 选择课程弹框 -->
    <base-dialog class="choose-course-dialog ms-change-width" ref="chooseCourseDialogRef" title="选择课程" @determine="determineChooseCourseDialog" @cancel="cancelChooseCourseDialog" @close="closeChooseCourseDialog" :pagination="true" :parentPage="chooseCourseForm.currentPage" :pageSize="chooseCourseForm.pageSize" :total="chooseCourseFormExp.total" @currentPage="queryCourseList">
      <div class="choose-course-body" style="height: 300px;">
        <el-form class="zwx-form" key="chooseCourseForm" ref="chooseCourseForm" :model="chooseCourseForm" label-position="right" @submit.native.prevent style="display:none;">
          <div class="self-adapting-condition">
            <el-form-item label="课程名称：">
              <el-input style="width:250px !important" class="zwx-input" v-model.trim="chooseCourseForm.courseNameLike" placeholder="" maxlength="6" clearable />
            </el-form-item>
            <div class="condition-button-row">
              <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryCourseList(1)">查询</el-button>
            </div>
          </div>
        </el-form>
        <!-- 列表展示部分 -->
        <el-table class="zwx-table choose-course-table" row-key="rid" :data="chooseCourseFormExp.tableList" border stripe tooltip-effect="light">
          <el-table-column width="80" header-align="center" align="center">
            <template slot-scope="scope">
              <el-radio :label="scope.row.uuid" style="margin-left: 10px" v-model="chooseCourseForm.radio" @change="changeSelect(scope.row)">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="courseName" label="课程名称" min-width="300" header-align="center" align="left"></el-table-column>
          <!--
          <el-table-column prop="existExam" label="是否包含考试" width="120" header-align="center" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.existExam === true">
                <div class="zwx-circle-normal">是</div>
              </template>
              <template v-if="scope.row.existExam === false">
                <div class="zwx-circle-underway">否</div>
              </template>
            </template>
          </el-table-column>
          -->
          <el-table-column prop="totalClassHour" label="总课时" width="90" header-align="center" align="center" />
        </el-table>
      </div>
    </base-dialog>
  </div>
</template>

<script>
//根据课程uid获取课时信息
function fetchProduct(uuid) {
  this.$system.post(
    this.api + '/training/platform/getShelveGoods-1',
    { uuid: uuid },
    true,
    true,
    data => {
      this.$emit('loading', false)
      if (data.type == '00') {
        this.ifDisabled = data.resultData.soldOutQty > 0
        this.isShowDel = !this.ifDisabled
        this.goodsEditForm = data.resultData
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    data => {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
    }
  )
}

export default {
  name: 'ShelveGoodsManagementEdit',
  data() {
    return {
      isShow: true,
      ifDisabled: false,
      isUpload: false,
      isShowDel: false,
      isShowSearch: false,
      opType: this.$route.params.type,
      api: this.$store.state.api,
      file: this.$store.state.file,
      folder: '/om/shelve_goods_management', //文件存储路径
      goodsEditForm: {
        /**
         * 
        rid: '',
        coursePic: '',
        courseUid: '',
        productDetail: '',
        targetPopulation: '',
        courseCategory: '',
        goodsName: '',
        goodsNo: '',
        expiryDate: '',
        goodsNoBefore: '',
        signCertificate: true,
        price: '',
        salePrice: '',
        merchandisngState: '',
        photoAnnexList: [],
        fileName: '',
        totalClassHour: '',
        requiredHour: '',
        electiveHour: '',
        requiredClassHour: '',
        tcCode: '',
        certificateOrgan: '', //发证机构
        recommendDisplay: false, //是否推荐展示
         */

        uuid: undefined,
        courseUid: '',
        goodsName: '',
        price: undefined,
        salePrice: undefined,
        recommendDisplay: true,
        expiryDate: undefined,
        merchandisngState: undefined,
        courseExp: {},
      },
      simpleCodeList: [],
      // 选择课程弹框
      chooseCourseForm: {
        currentPage: 1, // 页码
        pageSize: 7, // 条数
        courseNameLike: '', // 课程名称，模糊查询
        radio: [],
        choosedCourseInfo: null,
      },
      chooseCourseFormExp: {
        tableList: [],
        total: 0,
      },
      goodsEditFormRules: {
        goodsName: [{ required: true, message: '请选择商品名称', trigger: ['change'] }],
        certificateOrgan: [{ required: true, message: '请输入发证机构', trigger: ['change'] }],
        goodsNo: [
          { required: true, message: '请输入商品编号', trigger: ['blur'] },
          { validator: this.validateGoodsNo, trigger: ['blur'] },
        ],
        // tcCode: [{ required: true, message: '请选择课程类型', trigger: ['change'] }],
        totalClassHour: [{ pattern: /^([0-9]+\.[0-9]*)|([0-9]*)$/, message: '请输入大于等于0的整数' }],
        expiryDate: [{ required: true, message: '请输入有效日期', trigger: ['blur'] }],
        price: [{ required: true, message: '请输入商品原价', trigger: ['blur'] }],
        salePrice: [{ required: true, message: '请输入商品售价', trigger: ['blur'] }],
      },
    }
  },
  mounted() {
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
      this.simpleCodeList = data['5002']
      this.$nextTick(() => {
        this.init()
      })
    })
  },
  methods: {
    init() {
      if (this.opType === 'add') {
        this.$emit('breadcrumb', '添加记录', true)
        this.isShowSearch = true
      } else {
        this.$emit('breadcrumb', '编辑记录', true)
        /**
         * 
        this.goodsEditForm.rid = this.$route.params.row.rid
        this.goodsEditForm.goodsNo = this.$route.params.row.goodsNo
        this.goodsEditForm.goodsNoBefore = this.$route.params.row.goodsNo
        this.goodsEditForm.goodsName = this.$route.params.row.goodsName
        this.goodsEditForm.price = this.$route.params.row.price
        this.goodsEditForm.salePrice = this.$route.params.row.salePrice
        this.goodsEditForm.expiryDate = this.$route.params.row.expiryDate
        this.goodsEditForm.targetPopulation = this.$route.params.row.targetPopulation
        this.goodsEditForm.courseCategory = this.$route.params.row.courseCategory
        this.goodsEditForm.tcCode = this.goodsEditForm.courseCategory
        this.goodsEditForm.certificateOrgan = this.$route.params.row.certificateOrgan
        this.goodsEditForm.recommendDisplay = this.$route.params.row.recommendDisplay
        this.goodsEditForm.signCertificate = this.$route.params.row.signCertificate
        this.goodsEditForm.productDetail = this.$route.params.row.productDetail
        this.goodsEditForm.merchandisngState = this.$route.params.row.merchandisngState
        this.goodsEditForm.courseUid = this.$route.params.row.courseUid
         */

        // this.goodsEditForm = this.$route.params.row
        fetchProduct.call(this, this.$route.params.row.uuid)
        if (this.$route.params.row.courseUid) {
          // this.findHourByCourseUid(this.$route.params.row.courseUid)
        }
        /**
         * 
        if (this.$zwxBase.verifyIsNotBlank(this.$route.params.row.coursePic)) {
          this.goodsEditForm.coursePic = this.$route.params.row.coursePic
          let filePath = this.goodsEditForm.coursePic
          let fileName = this.goodsEditForm.goodsName + filePath.substr(filePath.lastIndexOf('.'), filePath.length)
          this.goodsEditForm.photoAnnexList.push({
            fileName: fileName,
            filePath: filePath,
            fileIconShow: this.$zwxBase.dealfilePrefix(filePath),
            check: false,
          })
        }
         */
        this.isShow = true
        this.ifDisabled = true
        this.isShowDel = true
      }
    },
    //根据课程uid获取课时信息
    findHourByCourseUid(courseUid) {
      this.$system.get(
        this.api + '/online/training/getTrainingCourseByUuidIncludeHour-1',
        { courseUid: courseUid },
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.goodsEditForm.totalClassHour = data.trainingCourse.totalClassHour
            this.goodsEditForm.requiredClassHour = data.trainingCourse.requiredClassHour
            this.goodsEditForm.requiredHour = data.trainingCourse.requiredHour
            this.goodsEditForm.electiveHour = data.trainingCourse.electiveHour
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 修改商品类别
     */
    //清除课时相关字段
    clearClassHour() {
      this.goodsEditForm.totalClassHour = ''
      this.goodsEditForm.requiredClassHour = ''
      this.goodsEditForm.requiredHour = ''
      this.goodsEditForm.electiveHour = ''
    },
    /**
     * 清除商品名称时，同时清除相关信息
     */
    clearGoodsName() {
      this.$system.msgbox('', '提示', '删除商品名称将清空页面信息,是否确定？', '确定', '取消', () => {
        this.goodsEditForm.goodsName = ''
        this.goodsEditForm.coursePic = ''
        this.goodsEditForm.photoAnnexList = []
        this.goodsEditForm.productDetail = ''
        this.clearClassHour()
        this.goodsEditForm.price = ''
        this.goodsEditForm.salePrice = ''
        this.goodsEditForm.expiryDate = ''
        this.goodsEditForm.courseExp = {}
        this.ifDisabled = false
        this.isShowSearch = true
        this.isShowDel = false
        return true
      })
    },
    validateGoodsNo(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (this.opType === 'add') {
          this.ifExistGoodsNo(value, flag => {
            if (flag) {
              callback(new Error('当前商品编号已存在'))
            }
            callback()
          })
        } else {
          if (this.goodsEditForm.goodsNoBefore !== this.goodsEditForm.goodsNo) {
            this.ifExistGoodsNo(value, flag => {
              if (flag) {
                callback(new Error('当前商品编号已存在'))
              }
              callback()
            })
          } else {
            callback()
          }
        }
      } else {
        callback()
      }
    },
    ifExistGoodsNo(val, callback) {
      let data = {
        goodsNo: val,
      }
      this.$system.get(
        this.api + '/zky/training/getShelveGoodsInfoWithGoodsNo-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            if (this.$zwxBase.verifyIsNotBlank(data.shelveGoods)) {
              callback(true)
            } else {
              callback(false)
            }
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    /**
     * 打开添加课程弹框
     */
    showChooseCourseDialog() {
      this.$refs.chooseCourseDialogRef.show(true)
      this.chooseCourseForm.courseNameLike = this.goodsEditForm.goodsName
      this.chooseCourseForm.radio = []
      this.queryCourseList(1)
    },
    /**
     * 查询课程列表
     */
    queryCourseList(currentPage) {
      this.chooseCourseForm.currentPage = currentPage
      let data = {
        // ...this.chooseCourseForm,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/getCourseChooseList1-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.chooseCourseFormExp.tableList = data.resultList
            this.chooseCourseFormExp.total = data.resultList.length
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
    },
    /**
     * 确认课程弹框
     */
    determineChooseCourseDialog() {
      if (this.$zwxBase.verifyIsNotBlank(this.chooseCourseForm.choosedCourseInfo.coverPicture)) {
        this.isUpload = false
        this.goodsEditForm.coursePic = this.chooseCourseForm.choosedCourseInfo.coverPicture

        if (this.$zwxBase.verifyIsNotBlank(this.goodsEditForm.coursePic)) {
          let filePath = this.goodsEditForm.coursePic
          let fileName = filePath.substr(filePath.lastIndexOf('/') + 1, filePath.length)
          //先置空再赋值，否则会出现多个图片同行排列
          this.goodsEditForm.photoAnnexList = []
          this.goodsEditForm.photoAnnexList.push({
            fileName: fileName,
            filePath: filePath,
            fileIconShow: this.$zwxBase.dealfilePrefix(filePath),
            check: false,
          })
        }
      }
      if (this.$zwxBase.verifyIsNotBlank(this.chooseCourseForm.choosedCourseInfo.courseDesc)) {
        this.goodsEditForm.productDetail = this.chooseCourseForm.choosedCourseInfo.courseDesc
      }
      if (this.$zwxBase.verifyIsNotBlank(this.chooseCourseForm.choosedCourseInfo.totalClassHour)) {
        this.ifDisabled = true
        /*this.goodsEditForm.totalClassHour = this.chooseCourseForm.choosedCourseInfo.totalClassHour
        this.goodsEditForm.requiredClassHour = this.chooseCourseForm.choosedCourseInfo.requiredClassHour
        this.goodsEditForm.requiredHour = this.chooseCourseForm.choosedCourseInfo.requiredHour
        this.goodsEditForm.electiveHour = this.chooseCourseForm.choosedCourseInfo.electiveHour*/
        // this.findHourByCourseUid(this.chooseCourseForm.choosedCourseInfo.uuid)
      }
      this.goodsEditForm.goodsName = this.chooseCourseForm.choosedCourseInfo.courseName
      this.isShowDel = true
      this.isShowSearch = false
      this.goodsEditForm.courseUid = this.chooseCourseForm.choosedCourseInfo.uuid
      this.goodsEditForm.courseExp = this.chooseCourseForm.choosedCourseInfo

      this.$refs.chooseCourseDialogRef.show(false)
      this.$nextTick(() => {
        this.$refs.goodsEditFormRef.clearValidate('goodsName')
      })
    },
    /**
     * 取消课程弹框
     */
    cancelChooseCourseDialog() {
      this.$refs.chooseCourseDialogRef.show(false)
    },
    /**
     * 关闭课程弹框回调
     */
    closeChooseCourseDialog() {
      this.chooseCourseForm.currentPage = 1
      this.chooseCourseForm.pageSize = 7
      this.chooseCourseForm.courseName = ''
      this.chooseCourseForm.targetPopulationList = []
      this.chooseCourseForm.courseCategoryList = []
      this.chooseCourseForm.tableList = []
      this.chooseCourseForm.total = 0
      this.chooseCourseForm.choosedCourseInfo = []
    },
    changeSelect(row) {
      this.chooseCourseForm.choosedCourseInfo = row
    },
    /**
     * 图片预览
     */
    openFilePreview(filePath) {
      if (this.$zwxBase.verifyIsNotBlank(filePath)) {
        let fileName = filePath.substr(filePath.lastIndexOf('/') + 1, filePath.length)
        let fileList = []
        fileList.push({
          fileName: fileName,
          filePath: filePath,
        })
        top.postMessage(
          {
            handlerType: 'previewFile',
            params: {
              fileList: fileList,
              index: 0,
            },
          },
          '*'
        )
      }
    },
    uploadPhoto(showList) {
      showList.forEach(item => {
        let flag = this.goodsEditForm.photoAnnexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.goodsEditForm.fileName = item
        this.goodsEditForm.photoAnnexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          filePath: window.URL.createObjectURL(this.$refs.fileUploadRef.uploadFiles[this.$refs.fileUploadRef.uploadFiles.length - 1].raw),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.isUpload = true
        this.goodsEditForm.coursePic = this.goodsEditForm.photoAnnexList[0].filePath
        this.generateFilesUid()
      }
    },
    /**
     * 删除文件
     */
    deletionPositive() {
      this.goodsEditForm.photoAnnexList = []
      this.goodsEditForm.coursePic = ''
      this.$refs.fileUploadRef.deleteIndex(0)
    },
    /**
     * 鼠标悬浮事件
     */
    mouseenter(index) {
      this.goodsEditForm.photoAnnexList[index].check = true
    },
    /**
     * 鼠标离开事件
     */
    mouseleave(index) {
      this.goodsEditForm.photoAnnexList[index].check = false
    },
    uploadSuccessCallback(file) {
      if (this.$zwxBase.verifyIsNotBlank(file)) {
        this.goodsEditForm.coursePic = file[file.length - 1].filePath
        this.goodsEditForm.photoAnnexList[0].filePath = file[file.length - 1].filePath
        //this.save()
      }
    },
    /**
     * 生成文件存储信息
     */
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.post(
        this.api + '/zky/employer/generateFilesUidOperations-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.fileUploadRef.submit(data.uid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    submit(state) {
      this.$refs['goodsEditFormRef'].validate(valid => {
        if (valid) {
          // this.goodsEditForm.merchandisngState = state
          this.$set(this.goodsEditForm, 'merchandisngState', state)
          /*if (this.isUpload) {
            this.generateFilesUid()
          } else {
            this.save()
          }*/
          this.save()
        }
      })
    },
    save() {
      let data = {
        ...this.goodsEditForm,
      }
      this.$set(data, 'creditHourRequirement', data.requiredClassHour)
      this.$set(data, 'requiredClassHour', data.requiredHour)
      this.$set(data, 'electiveClassHour', data.electiveHour)
      this.$system.postJson(
        this.api + '/training/platform/addOrModifyShelveGoods-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$emit('loading', false)
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({ name: 'product', params: { fresh: true } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //级联选择change
    changeCascader(value) {
      if (value) {
        let simpleArr = this.simpleCodeList.filter(item => item.codeNo == value)
        this.goodsEditForm.targetPopulation = simpleArr[0].codeLevelNo.split('.')[0]
        this.goodsEditForm.courseCategory = simpleArr[0].codeLevelNo.split('.')[1]
      } else {
        this.goodsEditForm.targetPopulation = ''
        this.goodsEditForm.courseCategory = ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ms-cascader-single-common .zwx-input {
  width: 200px !important;
}

/deep/ .ms-change > .el-input.el-input--suffix > .el-input__inner {
  width: 200px !important;
}

/deep/ .ms-change-width .el-dialog {
  width: 590px !important;
  min-width: 590px !important;
  .base-pagination {
    display: none;
  }
}
.edit-row {
  display: flex;
  flex-direction: column;
}
</style>
