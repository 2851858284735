var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ms-shelve-goods-edit" },
    [
      _c(
        "el-form",
        {
          key: "goodsEditForm",
          ref: "goodsEditFormRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.goodsEditForm,
            rules: _vm.goodsEditFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "商品名称：",
                    prop: "goodsName"
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "zwx-input",
                      staticStyle: { width: "240px !important" },
                      attrs: {
                        placeholder: "请选择",
                        readonly: "",
                        disabled:
                          _vm.ifDisabled ||
                          _vm.goodsEditForm.merchandisngState === -1
                      },
                      on: {
                        focus: function($event) {
                          return _vm.showChooseCourseDialog()
                        }
                      },
                      model: {
                        value: _vm.goodsEditForm.goodsName,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.goodsEditForm,
                            "goodsName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "goodsEditForm.goodsName"
                      }
                    },
                    [
                      _vm.isShowSearch &&
                      _vm.goodsEditForm.merchandisngState != -1
                        ? _c("template", { slot: "append" }, [
                            _c("span", [
                              _c("i", {
                                staticClass: "el-icon-search",
                                on: { click: _vm.showChooseCourseDialog }
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm.isShowDel && _vm.goodsEditForm.merchandisngState != -1
                        ? _c("template", { slot: "append" }, [
                            _c("span", [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.clearGoodsName }
                              })
                            ])
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "110px", label: "课程分类：" } },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp.courseTraineeType
                                ? _vm.simpleCodeList.find(
                                    ({ codeNo }) =>
                                      codeNo ===
                                      _vm.goodsEditForm.courseExp
                                        .courseTraineeType
                                  ).codeName
                                : "-"
                            )
                          )
                        ])
                      : _c(
                          "el-select",
                          {
                            staticClass: "zwx-select",
                            attrs: {
                              "popper-class": "zwx-select-popper",
                              disabled: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value:
                                _vm.goodsEditForm.courseExp.courseTraineeType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.goodsEditForm.courseExp,
                                  "courseTraineeType",
                                  $$v
                                )
                              },
                              expression:
                                "goodsEditForm.courseExp.courseTraineeType"
                            }
                          },
                          _vm._l(_vm.simpleCodeList, function(item) {
                            return _c("el-option", {
                              key: item.codeNo,
                              attrs: {
                                label: item.codeName,
                                value: item.codeNo
                              }
                            })
                          }),
                          1
                        )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "课程类型：",
                      prop: "tcCode"
                    }
                  },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp.courseTrainingType
                                ? _vm.simpleCodeList.find(
                                    ({ codeNo }) =>
                                      codeNo ===
                                      _vm.goodsEditForm.courseExp
                                        .courseTrainingType
                                  ).codeName
                                : "-"
                            )
                          )
                        ])
                      : _c("cascader-single", {
                          ref: "cascader",
                          attrs: {
                            disabled: "",
                            simpleCodeList: _vm.simpleCodeList,
                            expandTrigger: "hover",
                            codeTypeCode: "5002"
                          },
                          on: { change: _vm.changeCascader },
                          model: {
                            value:
                              _vm.goodsEditForm.courseExp.courseTrainingType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.goodsEditForm.courseExp,
                                "courseTrainingType",
                                $$v
                              )
                            },
                            expression:
                              "goodsEditForm.courseExp.courseTrainingType"
                          }
                        })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "总学时：",
                      prop: "totalClassHour"
                    }
                  },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp.totalClassHour || "-"
                            )
                          )
                        ])
                      : _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "75px !important" },
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.goodsEditForm.courseExp.totalClassHour,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.goodsEditForm.courseExp,
                                "totalClassHour",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "goodsEditForm.courseExp.totalClassHour"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "必修学时：",
                      prop: "requiredHour"
                    }
                  },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp
                                .onlineRequiredClassHour || "-"
                            )
                          )
                        ])
                      : _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "75px !important" },
                          attrs: { disabled: "" },
                          model: {
                            value:
                              _vm.goodsEditForm.courseExp
                                .onlineRequiredClassHour,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.goodsEditForm.courseExp,
                                "onlineRequiredClassHour",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "goodsEditForm.courseExp.onlineRequiredClassHour"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "选修学时：",
                      prop: "electiveHour"
                    }
                  },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp.electiveClassHour ||
                                "-"
                            )
                          )
                        ])
                      : _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "75px !important" },
                          attrs: { disabled: "" },
                          model: {
                            value:
                              _vm.goodsEditForm.courseExp.electiveClassHour,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.goodsEditForm.courseExp,
                                "electiveClassHour",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "goodsEditForm.courseExp.electiveClassHour"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "学时要求：",
                      prop: "requiredClassHour"
                    }
                  },
                  [
                    true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.goodsEditForm.courseExp.requiredClassHour ||
                                "-"
                            )
                          )
                        ])
                      : _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "75px !important" },
                          attrs: { disabled: "" },
                          model: {
                            value:
                              _vm.goodsEditForm.courseExp.requiredClassHour,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.goodsEditForm.courseExp,
                                "requiredClassHour",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "goodsEditForm.courseExp.requiredClassHour"
                          }
                        })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "是否推荐展示：",
                      prop: "recommendDisplay"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { width: "200px !important" },
                        model: {
                          value: _vm.goodsEditForm.recommendDisplay,
                          callback: function($$v) {
                            _vm.$set(_vm.goodsEditForm, "recommendDisplay", $$v)
                          },
                          expression: "goodsEditForm.recommendDisplay"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: true } },
                          [_vm._v("是")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: false } },
                          [_vm._v("否")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "110px",
                      label: "有效日期(天)：",
                      prop: "expiryDate"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请输入",
                        clearable: "",
                        maxlength: "9"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$validate.number(
                            _vm.$data["goodsEditForm"],
                            "expiryDate",
                            9,
                            0,
                            0
                          )
                        }
                      },
                      model: {
                        value: _vm.goodsEditForm.expiryDate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.goodsEditForm,
                            "expiryDate",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "goodsEditForm.expiryDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "原价：",
                    prop: "price"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "22"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$validate.number(
                          _vm.$data["goodsEditForm"],
                          "price",
                          -1,
                          2,
                          0
                        )
                      }
                    },
                    model: {
                      value: _vm.goodsEditForm.price,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.goodsEditForm,
                          "price",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "goodsEditForm.price"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "售价：",
                    prop: "salePrice"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "200px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "22"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$validate.number(
                          _vm.$data["goodsEditForm"],
                          "salePrice",
                          -1,
                          2,
                          0
                        )
                      }
                    },
                    model: {
                      value: _vm.goodsEditForm.salePrice,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.goodsEditForm,
                          "salePrice",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "goodsEditForm.salePrice"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.submit(0)
                }
              }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit(1)
                }
              }
            },
            [_vm._v("上架")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "chooseCourseDialogRef",
          staticClass: "choose-course-dialog ms-change-width",
          attrs: {
            title: "选择课程",
            pagination: true,
            parentPage: _vm.chooseCourseForm.currentPage,
            pageSize: _vm.chooseCourseForm.pageSize,
            total: _vm.chooseCourseFormExp.total
          },
          on: {
            determine: _vm.determineChooseCourseDialog,
            cancel: _vm.cancelChooseCourseDialog,
            close: _vm.closeChooseCourseDialog,
            currentPage: _vm.queryCourseList
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "choose-course-body",
              staticStyle: { height: "300px" }
            },
            [
              _c(
                "el-form",
                {
                  key: "chooseCourseForm",
                  ref: "chooseCourseForm",
                  staticClass: "zwx-form",
                  staticStyle: { display: "none" },
                  attrs: {
                    model: _vm.chooseCourseForm,
                    "label-position": "right"
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "self-adapting-condition" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "课程名称：" } },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input",
                            staticStyle: { width: "250px !important" },
                            attrs: {
                              placeholder: "",
                              maxlength: "6",
                              clearable: ""
                            },
                            model: {
                              value: _vm.chooseCourseForm.courseNameLike,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.chooseCourseForm,
                                  "courseNameLike",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "chooseCourseForm.courseNameLike"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "condition-button-row" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-28",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.queryCourseList(1)
                                }
                              }
                            },
                            [_vm._v("查询")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-table",
                {
                  staticClass: "zwx-table choose-course-table",
                  attrs: {
                    "row-key": "rid",
                    data: _vm.chooseCourseFormExp.tableList,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "light"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "80",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { label: scope.row.uuid },
                                on: {
                                  change: function($event) {
                                    return _vm.changeSelect(scope.row)
                                  }
                                },
                                model: {
                                  value: _vm.chooseCourseForm.radio,
                                  callback: function($$v) {
                                    _vm.$set(_vm.chooseCourseForm, "radio", $$v)
                                  },
                                  expression: "chooseCourseForm.radio"
                                }
                              },
                              [_vm._v(" ")]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseName",
                      label: "课程名称",
                      "min-width": "300",
                      "header-align": "center",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalClassHour",
                      label: "总课时",
                      width: "90",
                      "header-align": "center",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }